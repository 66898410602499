<template>
  <pt-task-order class="pt-task-order__withData" :statisticData="statisticData"></pt-task-order>
</template>

<script>
import PtTaskOrder from './index'
export default {
  name: 'pt-task-order__withData',
  components: {
    'pt-task-order': PtTaskOrder
  },
  data (){
    return {
      statisticData:{}
    }
  },
  methods:{
    getTaskStatistic (){
      this.$loading()
      this.$store.dispatch('getTaskStatistic').then(res => {
        this.statisticData = res
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取生产任务统计数据失败')
      })
    }
  },
  created (){
    this.getTaskStatistic()
  }
}
</script>
