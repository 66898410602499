<template>
  <pt-task-order class="pt-task-order__page"></pt-task-order>
</template>

<script>
import PtTaskOrder from 'cps//pt-task-order/withData'
export default {
  name: 'pt-task-order__view',
  components: {
    'pt-task-order': PtTaskOrder
  }
}
</script>
